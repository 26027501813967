import React from "react";

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    Row,
    Col
} from "reactstrap";
import IcaroModal from "./Modals/IcaroModal";

class Icaro extends React.Component {
    state = {
        iconTabs: 1,
        plainTabs: 1
    };
    toggleNavs = (e, state, index) => {
        e.preventDefault();
        this.setState({
            [state]: index
        });
    };
    render() {
        return (
            <Card className="card-profile shadow mt--3">
                <CardBody>
                    <div className="px-1">
                        <Row className="justify-content-center">
                            <Col className="order-lg-6" lg="6">
                                <div className="card-profile-image">
                                    <a href="!#" onClick={e => e.preventDefault()}>
                                        <img
                                            alt="..."
                                            className="rounded-circle"
                                            src={require("../../../assets/img/equipe/icaro.jfif")}
                                        />
                                    </a>
                                </div>
                            </Col>
                            <Col className="order-lg-6" lg="6">
                                <div className="text-center">
                                    <h3>
                                        <div className="card-profile-name">Ícaro Castro</div>
                                        <div className="card-profile-actions mt-lg-0">
                                            <Button
                                                className="nav-link-icon"
                                                href="https://github.com/icaromsc"
                                                id="tooltip112445449"
                                                target="_blank"
                                            >
                                                <i className="fa fa-github" />
                                            </Button>
                                            <Button
                                                className="nav-link-icon"
                                                href="http://lattes.cnpq.br/6372338171321706"
                                                target="_blank"
                                                color="default"
                                            >
                                                <div className="icon icon-shape-2 icon-sm  rounded-circle text-white">
                                                    <img alt='Lattes' width='80%' height='80%' src={require("../../../assets/img/lattes.png")}/>
                                                </div>
                                            </Button>
                                            <Button
                                                className="nav-link-icon"
                                                href="https://www.researchgate.net/profile/Icaro_Castro3"
                                                color="default"
                                                target="_blank"
                                            >
                                                <div className="icon icon-shape-2 icon-sm  rounded-circle text-white">
                                                    <img alt='Researchgate' width='80%' height='80%' src={require("../../../assets/img/researchgate-logo.png")}/>
                                                </div>
                                            </Button>
                                            <IcaroModal/>
                                        </div>
                                    </h3>
                                    <div className="h6 mt-4">
                                        <i className="ni business_briefcase-24 mr-2" />
                                        Graduado em Informática Biomédica pela Universidade Federal de Ciências da Saúde de Porto Alegre (UFCSPA)
                                    </div>
                                    <div>
                                        <i className="ni education_hat mr-2" />
                                        Doutorando em Bioinformática pela Universidade de São Paulo (USP)
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </CardBody>
            </Card>
        );
    }
}

export default Icaro;