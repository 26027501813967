import React from "react";
// reactstrap components
import {
    Button,
    Modal
} from "reactstrap";

class NaiaraModal extends React.Component {
    state = {};
    toggleModal = state => {
        this.setState({
            [state]: !this.state[state]
        });
    };
    render() {
        return (
            <>

                <Button
                    className="mb-1"
                    type="button"
                    onClick={() => this.toggleModal("notificationModal")}
                >
                    <i className="fa fa-graduation-cap" />
                </Button>
                <Modal
                    className="modal-dialog-centered modal-default"
                    contentClassName="bg-gradient-default"
                    isOpen={this.state.notificationModal}
                    toggle={() => this.toggleModal("notificationModal")}
                >
                    <div className="modal-header">
                        <h6 className="modal-title" id="modal-title-notification">
                            Projetos
                        </h6>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => this.toggleModal("notificationModal")}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="py-3">
                            <div className="text-center">
                                <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                                    <img alt="..." width='140%' height='140%' src={require("../../../../assets/img/sea-lion.png")}/>
                                </div>
                                <h4 className="heading mt-4">Mestrado</h4>
                            </div>
                            <hr/>
                            <p>
                                2012/2– 2014/2:  Isolamento e avaliação de <i> Enterococcus </i> spp. obtidos de amostras fecais de lobos-marinhos (OTARIIDAE: <i> Arctocephalus </i> spp.) encontrados no Litoral Norte do Rio Grande do Sul, Brasil
                            </p>
                            <p>
                                Orientadora: Profa. Dra. Ana Paula Guedes Frazzon
                            </p>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <Button
                            className="btn-white ml-auto"
                            color="default"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => this.toggleModal("notificationModal")}
                        >
                            Fechar
                        </Button>
                    </div>
                </Modal>
            </>
        );
    }
}

export default NaiaraModal;
