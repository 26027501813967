import React from "react";
import classnames from "classnames";
// reactstrap components
import { Button, Container, Row, Col, Card, NavItem, NavLink, Nav, CardBody, TabContent, TabPane,} from "reactstrap";

// core components
import Navbar from "./Navbars/Navbar.js";
import CardsFooter from "./Footers/CardsFooter.js";

class DivulgacaoCientifica extends React.Component {
    componentDidMount() {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.main.scrollTop = 0;
    }
    state = {
      iconTabs: 1,
      plainTabs: 1
    };
    toggleNavs = (e, state, index) => {
      e.preventDefault();
      this.setState({
        [state]: index
      });
    };
    render() {
        return (
            <>
                <Navbar />
                <main className="profile-page" ref="main">
                    <section className="section-profile-cover section-shaped my-0">
                        {/* Circles background */}
                        <div className="shape shape-style-1 shape-default alpha-4">
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                        </div>
                        {/* SVG separator */}
                        <div className="separator separator-bottom separator-skew">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio="none"
                                version="1.1"
                                viewBox="0 0 2560 100"
                                x="0"
                                y="0"
                            >
                                <polygon
                                    className="fill-white"
                                    points="2560 0 2560 100 0 100"
                                />
                            </svg>
                        </div>
                    </section>
                    <div className="position-relative">
                    <section className="section">
                        <Container>
                          <Card className="card-profile shadow mt--500">
                          <div className="mt-5 py-5 border-top">
                              <Row className="justify-content-center text-center">
                                  <Col lg="10">
                                      <h4>
                                          <b>Material de Divulgação Científica</b><br/>
                                      </h4>
                                      <p>Ilustrações digitais científicas de animais marinhos, produzidas pela aluna Janira Prichula durante sua tese, têm sido utilizadas em projeto de extensão que promove conhecimento sobre a fauna marinha do Rio Grande do Sul para escolas, universidades e público de outras áreas</p>
                                      <hr></hr>
                                      <div className="nav-wrapper">
                                        <Nav
                                          className="nav-fill flex-column flex-md-row"
                                          id="tabs-icons-text"
                                          pills
                                          role="tablist"
                                        >
                                          <NavItem>
                                            <NavLink
                                              aria-selected={this.state.iconTabs === 1}
                                              className={classnames("mb-sm-3 mb-md-0", {
                                                active: this.state.iconTabs === 1
                                              })}
                                              onClick={e => this.toggleNavs(e, "iconTabs", 1)}
                                              href="#marcadores"
                                              role="tab"
                                            >
                                              <i className="fa fa-bookmark mr-2" />
                                              Marcadores de Página
                                            </NavLink>
                                          </NavItem>
                                          <NavItem>
                                            <NavLink
                                              aria-selected={this.state.iconTabs === 2}
                                              className={classnames("mb-sm-3 mb-md-0", {
                                                active: this.state.iconTabs === 2
                                              })}
                                              onClick={e => this.toggleNavs(e, "iconTabs", 2)}
                                              href="#jogo"
                                              role="tab"
                                            >
                                              <i className="fa fa-ship mr-2" />
                                              Jogo Americano
                                            </NavLink>
                                          </NavItem>
                                        </Nav>
                                      </div>
                                      <Card className="shadow">
                                        <CardBody>
                                          <TabContent activeTab={"iconTabs" + this.state.iconTabs}>
                                            <TabPane tabId="iconTabs1">
                                              <Row className="justify-content-center">
                                                  <h6> Aves </h6>
                                                  <Col lg="12">
                                                            <img
                                                                alt="..."
                                                                className="img-fluid"
                                                                width="250px"
                                                                src={require("../assets/img/divulgacao/pinguim.png")}
                                                            />
                                                            <img
                                                                alt="..."
                                                                className="img-fluid"
                                                                width="250px"
                                                                src={require("../assets/img/divulgacao/pernelongo-de-costas-brancas.png")}
                                                            />

                                                  </Col>
                                                  <Col lg="12">
                                                            <img
                                                                alt="..."
                                                                className="img-fluid"
                                                                width="400px"
                                                                src={require("../assets/img/divulgacao/trinta-reis-de-coroa-branca.png")}
                                                            />
                                                  </Col>
                                                  <h6 style={{marginTop: "40px"}}> Tartarugas </h6>
                                                  <Col lg="12">
                                                            <img
                                                                alt="..."
                                                                className="img-fluid"
                                                                width="250px"
                                                                src={require("../assets/img/divulgacao/green_turtle.png")}
                                                            />
                                                            <img
                                                                alt="..."
                                                                className="img-fluid"
                                                                width="250px"
                                                                src={require("../assets/img/divulgacao/tartaruga-de-pente.png")}
                                                            />

                                                  </Col>
                                                  <h6 style={{marginTop: "40px"}}> Mamíferos </h6>
                                                  <Col lg="12">
                                                            <img
                                                                alt="..."
                                                                className="img-fluid"
                                                                width="400px"
                                                                src={require("../assets/img/divulgacao/baleia_jubarte.png")}
                                                            />
                                                  </Col>
                                                  <Col lg="12">
                                                            <img
                                                                alt="..."
                                                                className="img-fluid"
                                                                width="400px"
                                                                src={require("../assets/img/divulgacao/baleia_minke.png")}
                                                            />
                                                  </Col>
                                                  <Col lg="12">
                                                            <img
                                                                alt="..."
                                                                className="img-fluid"
                                                                width="400px"
                                                                src={require("../assets/img/divulgacao/golfinho_de_risso.png")}
                                                            />
                                                  </Col>
                                              </Row>
                                              <div className="p-6">
                                                  <Row className="align-items-center">
                                                          <Button
                                                              block
                                                              className="btn-white"
                                                              color="default"
                                                              size="lg"
                                                              href="https://www.ufrgs.br/ceclimar/faunamarinhars/"
                                                              target="_blank"
                                                          >
                                                              <i className="fa fa-external-link" /><br></br>
                                                                Para mais informações sobre os animais marinhos, visite o QR CODE
                                                          </Button>
                                                  </Row>
                                              </div>
                                            </TabPane>
                                            <TabPane tabId="iconTabs2">
                                              <div className="p-6">
                                                  <img
                                                      alt="..."
                                                      className="img-fluid"
                                                      src={require("../assets/img/divulgacao/jogo_americano_A3.png")}
                                                  />
                                                  <Row className="align-items-center" style={{marginTop: "50px"}}>
                                                          <Button
                                                              block
                                                              className="btn-white"
                                                              color="default"
                                                              size="lg"
                                                              href="https://www.ufrgs.br/ceclimar/faunamarinhars/"
                                                              target="_blank"
                                                          >
                                                              <i className="fa fa-external-link" /><br></br>
                                                                Para mais informações sobre os animais marinhos, visite o QR CODE
                                                          </Button>
                                                  </Row>
                                              </div>
                                            </TabPane>
                                          </TabContent>
                                        </CardBody>
                                      </Card>
                                      <hr></hr>
                                      <Card className="bg-gradient-blue shadow-lg border-0">
                                          <div className="p-6">
                                              <Row className="align-items-center">
                                                <p style={{color: "white"}}>O litoral gaúcho é uma das áreas de maior biodiversidade do Atlântico Sul Ocidental. Porém, a maioria das espécies que habitam este ecossistema é desconhecida pela população, devido à pouca disponibilidade de informações sobre o assunto. Foi pensando nisso que o projeto de extensão “Conhecendo a Fauna Marinha e Costeira do Litoral Norte do Rio Grande do Sul” foi criado e vem utilizando metodologias interativas digitais e de divulgação científica para ampliar os conhecimentos sobre essa temática para a comunidade.</p>
                                              </Row>
                                          </div>
                                      </Card>
                                  </Col>
                                </Row>
                          </div>
                          </Card>
                        </Container>
                    </section>
                    </div>
                </main>
                <CardsFooter />
            </>
        );
    }
}

export default DivulgacaoCientifica;
