import React from "react";

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    Row,
    Col
} from "reactstrap";
import JaniraModal from "./Modals/JaniraModal";

class Janira extends React.Component {
    state = {
        iconTabs: 1,
        plainTabs: 1
    };
    toggleNavs = (e, state, index) => {
        e.preventDefault();
        this.setState({
            [state]: index
        });
    };
    render() {
        return (
            <Card className="card-profile shadow mt--3">
                <CardBody>
                    <div className="px-1">
                        <Row className="justify-content-center">
                            <Col className="order-lg-6" lg="6">
                                <div className="card-profile-image">
                                    <a href="!#" onClick={e => e.preventDefault()}>
                                        <img
                                            alt="..."
                                            className="rounded-circle"
                                            src={require("../../../assets/img/equipe/janira.jpg")}
                                        />
                                    </a>
                                </div>
                            </Col>
                            <Col className="order-lg-6" lg="6">
                                <div className="text-center">
                                    <h3>
                                        <div className="card-profile-name">Janira Prichula</div>
                                        <div className="card-profile-actions mt-lg-0">
                                            <Button
                                                className="nav-link-icon"
                                                href="http://lattes.cnpq.br/4733135707203160"
                                                target="_blank"
                                            >
                                                <div className="icon icon-shape-2 icon-sm  rounded-circle text-white">
                                                    <img alt='Lattes' width='80%' height='80%' src={require("../../../assets/img/lattes.png")}/>
                                                </div>
                                            </Button>
                                            <Button
                                                className="nav-link-icon"
                                                href="https://www.researchgate.net/profile/Janira_Prichula"
                                                color="default"
                                                target="_blank"
                                            >
                                                <div className="icon icon-shape-2 icon-sm  rounded-circle text-white">
                                                    <img alt='Researchgate' width='80%' height='80%' src={require("../../../assets/img/researchgate-logo.png")}/>
                                                </div>
                                            </Button>
                                            <JaniraModal/>
                                        </div>
                                    </h3>
                                    <div className="h6 mt-4">
                                        <i className="ni business_briefcase-24 mr-2" />
                                        Graduada em Bacharelado e Licenciatura em Ciências Biológicas pela Universidade Federal do Rio Grande do Sul
                                    </div>
                                    <div>
                                        <i className="ni education_hat mr-2" />
                                        Mestre em Microbiologia Agrícola e do Ambiente pela Universidade Federal do Rio Grande do Sul (UFRGS) e
                                        Doutora pelo Programa de Pós-Graduação em Biociências da Universidade Federal de Ciências da Saúde de Porto Alegre (UFCSPA)
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </CardBody>
            </Card>
        );
    }
}

export default Janira;